<template>
  <v-container id="modulos" tag="section">
    <base-material-card
      color="primary"
      icon="mdi-view-module"
      inline
      class="px-5 py-3"
      :title="$t('modules')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" elevation="2" color="primary" @click.stop="dialog = true">
          Nuevo Módulo
        </v-btn>
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-overlay>

      <!-- Barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />
      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Módulo -->
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Nombre del módulo -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Módulo*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.modulo.$model"
                    :error-messages="moduloErrors"
                    @blur="$v.editedItem.modulo.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.modulo.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- Descripción del módulo -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Descripción*"
                    maxlength="50"
                    required
                    v-model="$v.editedItem.descripcion.$model"
                    :error-messages="descripcionErrors"
                    @blur="$v.editedItem.descripcion.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.descripcion.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- Titulo del módulo (usa la traducción) -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Título*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.titulo.$model"
                    :error-messages="tituloErrors"
                    @blur="$v.editedItem.titulo.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.titulo.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- Icono del módulo -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Icono*"
                    maxlength="30"
                    required
                    v-model="$v.editedItem.icono.$model"
                    :error-messages="iconoErrors"
                    @blur="$v.editedItem.icono.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.icono.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- Ruta del módulo -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Ruta*"
                    maxlength="20"
                    required
                    v-model="$v.editedItem.to.$model"
                    :error-messages="toErrors"
                    @blur="$v.editedItem.to.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.to.$reset()"
                  ></v-text-field>
                </v-col>
                <!-- Sistema al que pertenece el módulo -->
                <v-col cols="6">
                  <v-select
                    dense
                    label="Sistema*"
                    hint="Seleciona Sistema"
                    item-text="sistema"
                    item-value="_id"
                    persistent-hint
                    return-object
                    v-model="$v.editedItem.sistema.$model"
                    :error-messages="sistemaErrors"
                    :items="sistemas"
                    :menu-props="{ top: true, offsetY: true }"
                    @blur="$v.editedItem.sistema.$touch()"
                  >
                    <!-- <template #selection="{ item, parent }">
                      <v-chip color="blue" text-color="white" label small
                        ><span class="pr-2"> {{ item.sistema }}</span>
                        <v-icon x-small @click="parent.selectItem(item)">mdi-close</v-icon>
                      </v-chip>
                    </template> -->
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              v-if="editedIndex === -1"
              v-model="checkbox"
              label="Crear otro"
              :disabled="isEditing"
            ></v-checkbox>
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de Módulos -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="modulos"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot actions editar y eliminar -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>

        <!-- Slot del boton de Activo -->
        <template v-slot:item.activo="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    Status,
    ConfirmationDialog,
  },
  data: () => ({
    change: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      { text: 'Módulo', align: 'start', sortable: false, value: 'modulo' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Titulo', value: 'titulo' },
      { text: 'Icono', value: 'icono' },
      { text: 'Ruta', value: 'to' },
      { text: 'Sistema', value: 'sistema.sistema' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    modulos: [],
    sistemas: [],
    value: [],
    defaultItem: {
      modulo: '',
      descripcion: '',
      titulo: '',
      icono: '',
      to: '',
      sistema: '',
      activo: true,
    },
    editedItem: {
      modulo: '',
      descripcion: '',
      titulo: '',
      icono: '',
      to: '',
      sistema: '',
      activo: true,
    },
  }),
  mixins: [validationMixin],
  validations: {
    editedItem: {
      modulo: { required, minLength: minLength(3), maxLength: maxLength(20) },
      descripcion: { required, minLength: minLength(3), maxLength: maxLength(50) },
      titulo: { required, minLength: minLength(3), maxLength: maxLength(20) },
      icono: { required, minLength: minLength(3), maxLength: maxLength(30) },
      to: { required, minLength: minLength(3), maxLength: maxLength(20) },
      sistema: { required },
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Módulo' : 'Editar Módulo'
    },
    moduloErrors() {
      const errors = []
      if (!this.$v.editedItem.modulo.$dirty) return errors
      !this.$v.editedItem.modulo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.modulo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.modulo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    descripcionErrors() {
      const errors = []
      if (!this.$v.editedItem.descripcion.$dirty) return errors
      !this.$v.editedItem.descripcion.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.descripcion.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.descripcion.maxLength &&
        errors.push('Este campo debe de tener un máximo de 50 caracteres.')
      return errors
    },
    tituloErrors() {
      const errors = []
      if (!this.$v.editedItem.titulo.$dirty) return errors
      !this.$v.editedItem.titulo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.titulo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.titulo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    iconoErrors() {
      const errors = []
      if (!this.$v.editedItem.icono.$dirty) return errors
      !this.$v.editedItem.icono.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.icono.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.icono.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    toErrors() {
      const errors = []
      if (!this.$v.editedItem.to.$dirty) return errors
      !this.$v.editedItem.to.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.to.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.to.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    sistemaErrors() {
      const errors = []
      if (!this.$v.editedItem.sistema.$dirty) return errors
      !this.$v.editedItem.sistema.required && errors.push('Este campo es requerido')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerSistemas()
    this.obtenerModulos()
  },

  methods: {
    /**
     * @description Borrar en BD no funciona por el momento
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('module/deleteModule', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se eliminó con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
            // Enviar a inicio de sesión
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir del dialogo
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si cambio algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!this.checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Elimina un producto
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.modulos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      //Confirma cerrar formulario
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.modulos.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo asigna al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.modulos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de módulos
     * @return {array} arreglo con todaos los módulos
     */
    async obtenerModulos() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('module/fetchModules', payload)
        .then((response) => {
          this.modulos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de sistemas
     * @return {array} arreglo con todaos los sistemas
     */
    async obtenerSistemas() {
      let payload = { params: '?campo1=sistema&orden1=1' }
      this.$store
        .dispatch('system/fetchSystems', payload)
        .then((response) => {
          this.sistemas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del dialogo ya sea para un módulo nuevo o
     * la edicion de un módulo
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          modulo: this.editedItem.modulo,
          descripcion: this.editedItem.descripcion,
          titulo: this.editedItem.titulo,
          icono: this.editedItem.icono,
          to: this.editedItem.to,
          sistema: this.editedItem.sistema._id,
          // permisos: this.editedItem.permisos,
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          // Si es edicion =>
          payload.id = this.editedItem._id
          this.$store
            .dispatch('module/editModule', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.modulos[this.editedIndex], this.editedItem)
        } else {
          // Si es registro nuevo =>
          this.$store
            .dispatch('module/addModule', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creo con éxito',
              })
              this.modulos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close()
      }
    },
  },
}
</script>
